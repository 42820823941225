import React, { useState } from "react";

import "./ComingSoon.css";
import Logo from "./assets/images/logo.svg";
//social icon
import Instagram from "../src/assets/images/Instagram.svg";
import Linkedin from "../src/assets/images/LinkedIn.svg";
import Twitter from "../src/assets/images/Twitter.svg";


const ComingSoon = () => {
    const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubscribe = async () => {
    if (!email) {
      setMessage("Please enter an email address.");
      return;
    }
  
    try {
      const response = await fetch("http://localhost:5000/subscribe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
  
      const data = await response.json();
      setMessage(data.message);
      setEmail("");
    } catch (error) {
      setMessage("Subscription failed. Try again.");
    }
  };

  return (
    <div className="coming-soon-container">
      <div className="content">
        <div className="logo"> <img src={Logo} alt=""  className="logo-img"/></div>
        <p className="tag">COMING SOON</p>
        
        <div className="subscribe-box">
          <input  
           type="email"
          placeholder="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}/>
          <button  onClick={handleSubscribe}>Notify Me!</button>
        </div>
        {message && <p className="status-message">{message}</p>}
        <div className="social-icons">
          <i className="fab fa-linkedin"><a href=""><img src={Linkedin} alt=""  className="linkedin-icon"/></a></i>
          <i className="fab fa-twitter"><a href=""><img src={Twitter} alt="" className="twitter-icon"/></a></i>
          <i className="fab fa-instagram"><a href=""><img src={Instagram} alt="" className="instagram-icon"/></a></i>
          
        </div>
      </div>
      
    </div>
  );
};

export default ComingSoon;


import ComingSoonPage from './ComingSoon';

function App() {
  return (
    <div className="App">
      <ComingSoonPage/>
    </div>
  );
}

export default App;
